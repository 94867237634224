import React, { useContext } from "react"
import classes from "./Button.module.scss"
import { ButtonType } from "./types"
import { ThemeContext } from "../../../context/ThemeContext"

const Button = ({
  children,
  type,
  disabled,
  className,
  containerClassName,
  onClick,
}: ButtonType) => {
  const { theme } = useContext(ThemeContext)

  return (
    <div className={`${classes[theme]} ${containerClassName}`}>
      <button
        className={`${classes.button} ${className} `}
        onClick={onClick}
        disabled={disabled}
        type={type ? type : "button"}
      >
        {children}
      </button>
    </div>
  )
}

export default Button
