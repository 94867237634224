import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { ThemeProvider } from "./context/ThemeContext"
import { UserDataProvider } from "./context/UserDataContext"
import { SendAttackEntityProvider } from "./context/SendAttackEntityContext"
import { BrowserRouter } from "react-router-dom"
import HttpOkProvider from "./context/HttpOkContext"
import UserInfoProvider from "./context/UserInfoContext"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { AuthProvider } from "./context/AuthContext"

const rootElement = document.getElementById("root") as HTMLElement
rootElement.style.width = "100%"
rootElement.style.height = "100%"

const root = ReactDOM.createRoot(rootElement)

root.render(
  <ThemeProvider>
    <UserDataProvider>
      <HttpOkProvider>
        <SendAttackEntityProvider>
          <UserInfoProvider>
            <BrowserRouter basename="/">
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </UserInfoProvider>
        </SendAttackEntityProvider>
      </HttpOkProvider>
    </UserDataProvider>
  </ThemeProvider>
)
