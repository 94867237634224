import React, { useContext, useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { NavItemType } from "./types"
import classes from "./NavItem.module.scss"
import { ThemeContext } from "../../../../context/ThemeContext"

const NavItem = ({
  id,
  activeTab,
  setActiveTab,
  link,
  name,
  className,
  setShowNavbar,
}: NavItemType) => {
  const { theme } = useContext(ThemeContext)
  const location = useLocation()

  const [active, setActive] = useState("")

  useEffect(() => {
    if (location.pathname.includes(id)) {
      setActiveTab(id)
      setActive(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, activeTab])

  useEffect(() => {
    setActive("")
  }, [activeTab])

  const handleClick = () => {
    setActiveTab(id)
    setShowNavbar(false)
  }

  return (
    <li
      id={id}
      className={`${classes[theme]} ${classes.navContainer}`}
      onClick={handleClick}
    >
      <NavLink
        to={link}
        className={`${classes.itemNav} ${classes.itemNavInner} ${
          activeTab === id || active ? classes.itemNavClicked : ""
        }`}
      >
        <div className={classes.iconWrapper}>
          <div className={`${classes.icon} ${classes[className]}`} />
        </div>
        {name}
      </NavLink>
    </li>
  )
}

export default NavItem
