import React, { useState, useContext } from "react"

import { motion, AnimatePresence } from "framer-motion"

import { useNotificationCenter } from "react-toastify/addons/use-notification-center"
import styles from "./NotificationsCenter.module.scss"

import { Trigger } from "./Trigger"
import { ItemActions } from "./ItemActions/ItemActions"
import { TimeTracker } from "./TimeTracker"
import Toggle from "../../Toggle/Toggle"
import Button from "../Button/Button"
import IconWrapper from "./IconWrapper"
import { ThemeContext } from "../../../context/ThemeContext"
import { useTranslation } from "react-i18next"

const variants = {
  container: {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: -10,
      opacity: 0,
    },
  },
  // used to stagger item animation when switching from closed to open and vice versa
  content: {
    open: {
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.7,
        staggerChildren: 0.07,
        delayChildren: 0.2,
      },
    },
    closed: {
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.7,
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  },
  item: {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  },
}

export function NotificationCenter() {
  const {
    notifications,
    clear,
    markAllAsRead,
    markAsRead,
    remove,
    unreadCount,
  } = useNotificationCenter()

  const { theme } = useContext(ThemeContext)
  const { t } = useTranslation()

  const [showUnreadOnly, toggleFilter] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`${styles.wrapper} ${styles[theme]}`}>
      <Trigger onClick={() => setIsOpen(!isOpen)} count={unreadCount} />
      <motion.aside
        className={`${styles.container} ${!isOpen && styles.hiddenContainer}`}
        initial={false}
        variants={variants.container}
        animate={isOpen ? "open" : "closed"}
      >
        <header className={styles.header}>
          <div className={styles.unreadFilter}>
            <Toggle
              text={t("onlyUnread").toString()}
              defaultChecked={showUnreadOnly}
              onChange={() => {
                toggleFilter((prev) => !prev)
              }}
              uncheckedClass
            />
          </div>
        </header>
        <AnimatePresence>
          <motion.section
            className={styles.content}
            variants={variants.content}
            animate={isOpen ? "open" : "closed"}
          >
            {(!notifications.length ||
              (unreadCount === 0 && showUnreadOnly)) && (
              <motion.div
                className={styles.empty}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {t("empty")}
                <span role="img">🎉</span>
              </motion.div>
            )}
            <AnimatePresence>
              {(showUnreadOnly
                ? notifications.filter((v) => !v.read)
                : notifications
              ).map((notification) => {
                return (
                  <motion.div
                    key={notification.id}
                    layout
                    initial={{ scale: 0.4, opacity: 0, y: 50 }}
                    exit={{
                      scale: 0,
                      opacity: 0,
                      transition: { duration: 0.2 },
                    }}
                    animate={{ scale: 1, opacity: 1, y: 0 }}
                    style={{ padding: "0.3rem 0.8rem" }}
                  >
                    <motion.article
                      className={`${styles.item} ${
                        notification.type === "success" && styles.success
                      } ${notification.type === "warning" && styles.warning} ${
                        notification.type === "error" && styles.error
                      } ${notification.type === "info" && styles.info}`}
                      key={notification.id}
                      variants={variants.item}
                    >
                      <div>
                        {notification.type && (
                          <IconWrapper type={notification.type} />
                        )}
                      </div>
                      <div>
                        <div>{String(notification.content)}</div>
                        <TimeTracker createdAt={notification.createdAt} />
                      </div>
                      <ItemActions
                        notification={notification}
                        markAsRead={markAsRead}
                        remove={remove}
                      />
                    </motion.article>
                  </motion.div>
                )
              })}
            </AnimatePresence>
          </motion.section>
        </AnimatePresence>
        <footer className={styles.footer}>
          <Button className={styles.notificationsButton} onClick={clear}>
            {t("clearAll")}
          </Button>
          <Button
            className={styles.notificationsButton}
            onClick={markAllAsRead}
          >
            {t("markAsRead")}
          </Button>
        </footer>
      </motion.aside>
    </div>
  )
}
