import styles from "./Trigger.module.scss"
import { useContext } from "react"
import { ThemeContext } from "../../../context/ThemeContext"

interface Props {
  onClick: () => void
  count: number
}

export function Trigger({ count, onClick }: Props) {
  const { theme } = useContext(ThemeContext)

  return (
    <div
      className={`${styles.imageWrapper} ${styles[theme]}`}
      onClick={onClick}
    >
      <div className={styles.image} />
      <span>{count}</span>
    </div>
  )
}
