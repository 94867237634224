import React from "react"
import styles from "./PulsatingDot.module.scss"

export function PulsatingDot() {
  return (
    <div className={styles.circle}>
      <div className={styles.ring} />
    </div>
  )
}
