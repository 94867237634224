import React, { useState } from "react"
import styles from "./Toggle.module.scss"
import { useTranslation } from "react-i18next"

interface ToggleProps {
  defaultChecked?: boolean
  text?: string
  uncheckedClass?: boolean
  onChange?: (isChecked: boolean) => void
}

const Toggle: React.FC<ToggleProps> = ({
  defaultChecked,
  text,
  onChange,
  uncheckedClass,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked || false)
  const { t } = useTranslation()

  const handleToggle = () => {
    const newCheckedState = !isChecked
    setIsChecked(newCheckedState)
    if (onChange) {
      onChange(newCheckedState)
    }
  }

  return (
    <label className={styles.toggle}>
      <div>
        {isChecked ? (text ? text : t("showMy")) : text ? text : t("showAll")}
      </div>
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={isChecked}
        onChange={handleToggle}
      />
      <div
        className={`${styles.label} ${
          isChecked ? styles["toggle-on"] : styles["toggle-off"]
        } ${uncheckedClass ? styles.unchecked : ""}`}
      >
        <div className={styles.slider}></div>
      </div>
    </label>
  )
}

export default Toggle
