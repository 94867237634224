import React, { useEffect, useState } from "react"
import styles from "./NotificationsCenter.module.scss"
import errorIcon from "../../../assets/notification_error.svg"
import successIcon from "../../../assets/notification_success.svg"
import warningIcon from "../../../assets/notification_warning.svg"
import infoNotification from "../../../assets/infoNotification.svg"

type IconWrapperType = {
  type: string
}

const IconWrapper = ({ type }: IconWrapperType) => {
  const [icon, setIcon] = useState("")

  useEffect(() => {
    if (type === "success") {
      setIcon(successIcon)
    } else if (type === "warning") {
      setIcon(warningIcon)
    } else if (type === "error") {
      setIcon(errorIcon)
    } else if (type === "info") {
      setIcon(infoNotification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.iconWrapper}>
      <img src={icon} alt={type} />
    </div>
  )
}

export default IconWrapper
