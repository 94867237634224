import React, { createContext, useState } from "react"
import { UserDataProviderType, SendAttackEntityContextType } from "./types"
import { SendAttackEntity } from "../types/Entities"

export const SendAttackEntityContext =
  createContext<SendAttackEntityContextType>({
    sendAttackEntity: {} as SendAttackEntity,
    setSendAttackEntity: (
      value: SendAttackEntity | ((prev: SendAttackEntity) => SendAttackEntity)
    ) => {},
  })

export const SendAttackEntityProvider = ({
  children,
}: UserDataProviderType) => {
  const [sendAttackEntity, setSendAttackEntity] = useState<SendAttackEntity>(
    {} as SendAttackEntity
  )

  return (
    <SendAttackEntityContext.Provider
      value={{
        sendAttackEntity,
        setSendAttackEntity,
      }}
    >
      {children}
    </SendAttackEntityContext.Provider>
  )
}
