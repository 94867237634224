import { Theme, toast } from "react-toastify";
import { PORT_PATTERN, POST_PATTERN } from "./constants";
import { isIPv4 } from "is-ip";
import { SendAttackEntity, V4, V7 } from "./types/Entities";
import { validateHost } from "./services";
import errorIcon from "./assets/notification_error.svg";
import successIcon from "./assets/notification_success.svg";
import warningIcon from "./assets/notification_warning.svg";
import infoNotification from "./assets/infoNotification.svg";
import isUrl from "is-url";

export const isObjectEmpty = (obj?: V7 | V4) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const hostPatternValidation = (link: string) => {
  let invalidCharacter = "";
  const invalidCharRegex = /[;,:@+$!~*'()#]/; //"."

  let URL = link;
  const isIp = isIPv4(URL);
  if (!URL.includes("://") && !isIp) {
    URL = "https://" + link;
  }

  const is_url = isUrl(URL);

  if (!URL.includes("://") && isIp) {
    URL = "http://" + link;
  }

  if (is_url) {
    const { queryString } = extractURLParts(URL);

    if (queryString && invalidCharRegex.test(queryString)) {
      const match = queryString.match(invalidCharRegex);
      if (match) {
        invalidCharacter = match[0];
      }
    }
  }

  return {
    isValid: (is_url || isIp) && !invalidCharacter,
    invalidCharacter: invalidCharacter,
  };
};

export const ipPatternValidation = (ip: string) => {
  return isIPv4(ip) && ip !== "0.0.0.0" && ip !== "255.255.255.255";
};

function extractURLParts(link: string) {
  let baseUrl, queryString;
  let url = link;
  if (!url.includes("://")) {
    url = "https://" + link;
  }

  const parts = url.split("/");
  baseUrl = parts.slice(0, 3).join("/");
  queryString = parts.slice(3).join("/");

  return { baseUrl, queryString };
}

export const portPatternValidation = (port: string) => {
  const regex = new RegExp(PORT_PATTERN);
  return regex.test(port);
};

export const postPatternValidation = (post: string) => {
  const regex = new RegExp(POST_PATTERN);
  return regex.test(post);
};

export const notifyError = (theme: string, error?: string) => {
  toast.error(error, {
    position: toast.POSITION.TOP_RIGHT,
    theme: theme as Theme,
    closeOnClick: true,
    icon: () => <img src={errorIcon} alt="error" />,
  });
};

export const notifyErrorNoTheme = (error: string) => {
  toast.error(error, {
    position: toast.POSITION.TOP_RIGHT,
    closeOnClick: true,
  });
};

export const notifySuccess = (theme: string, success?: string) => {
  toast.success(success, {
    position: toast.POSITION.TOP_RIGHT,
    theme: theme as Theme,
    closeOnClick: true,
    icon: () => <img src={successIcon} alt="success" />,
  });
};

export const notifyPending = (theme: string, pending?: string) => {
  toast.info(pending, {
    position: toast.POSITION.TOP_RIGHT,
    theme: theme as Theme,
    closeOnClick: true,
    icon: () => <img src={infoNotification} alt="info" />,
  });
};

export const notifyWarning = (theme: string, warning?: string) => {
  toast.warn(warning, {
    position: toast.POSITION.TOP_RIGHT,
    theme: theme as Theme,
    closeOnClick: true,
    icon: () => <img src={warningIcon} alt="warning" />,
  });
};

export const convertHMS = (value: number) => {
  const sec = value;

  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;

  let hoursString = hours.toString();
  let minutesString = minutes.toString();
  let secondsString = seconds.toString();

  if (hours < 10) {
    hoursString = "0" + hours;
  }
  if (minutes < 10) {
    minutesString = "0" + minutes;
  }
  if (seconds < 10) {
    secondsString = "0" + seconds;
  }

  return `${hoursString}:${minutesString}:${secondsString}`;
};

export const numberWithSpace = (num?: number) => {
  if (!num) return;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const isValidEntity = async (
  entity: SendAttackEntity,
  theme: string,
  errorMessage: string
) => {
  const host = entity?.host ? entity.host : "";
  const validate = await validateHost(host);
  if (!validate) {
    notifyError(theme, errorMessage);
    return;
  }
  if (!validate?.can_use || !hostPatternValidation(host)) {
    return false;
  }

  const postData = entity?.postData ? entity.postData : "";
  if (postData !== "NULL" && !postPatternValidation(postData)) {
    return false;
  }

  const port = entity?.port ? entity.port : "";
  if (port !== "NULL" && !portPatternValidation(port)) {
    return false;
  }

  return true;
};

export const getFlagEmoji = (countryCode: string): string => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const createStringFromArray = (arr?: string[]) => {
  if (!arr) return;
  const layerMap: { [key: string]: string } = {
    L4: "Layer 4",
    L7: "Layer 7",
    L9: "Telegram",
  };

  const result: string[] = [];

  for (let i = 0; i < arr.length; i++) {
    const subArr: string = arr[i];
    const layer = layerMap[subArr];
    result.push(layer);
  }

  return result.join(", ");
};

export const splitOnSecondOccurrence = (
  inputString: string,
  character: string
) => {
  const firstOccurrence = inputString.indexOf(character);
  if (firstOccurrence !== -1) {
    const secondOccurrence = inputString.indexOf(
      character,
      firstOccurrence + 1
    );
    if (secondOccurrence !== -1) {
      const part1 = inputString.substring(0, secondOccurrence);
      const part2 = inputString.substring(secondOccurrence);
      return [part1, part2];
    }
  }
  return [null, null];
};

export const countOccurrences = (inputString: string, character: string) => {
  let count = 0;
  for (let i = 0; i < inputString.length; i++) {
    if (inputString.charAt(i) === character) {
      count++;
    }
  }
  return count;
};

export const delay = async (ms: number) =>
  new Promise((res) => setTimeout(res, ms));
