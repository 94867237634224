import { createContext, useContext, useEffect, useState } from "react"
import { ShortUserInfoType } from "../types/Entities"
import { UserDataContext } from "./UserDataContext"
import { getShortInfo, getUserKey } from "../services"
import { AuthContext } from "./AuthContext"

interface UserInfoContextType {
  userInfo?: ShortUserInfoType
  balance?: { current: number | null; prev: number | null }
  showModal: boolean
  setShowModal: (value: boolean) => void
  handleRefreshInfo: () => void
}

export const UserInfoContext = createContext<UserInfoContextType>({
  userInfo: undefined,
  showModal: false,
  setShowModal: (value: boolean) => {},
  handleRefreshInfo: () => {},
})

interface UserInfoProviderProps extends React.HTMLAttributes<HTMLDivElement> {}

const UserInfoProvider: React.FC<UserInfoProviderProps> = ({ children }) => {
  const { key, handleRefresh } = useContext(UserDataContext)
  const { logout } = useContext(AuthContext)

  const [userInfo, setUserInfo] = useState<ShortUserInfoType | undefined>(
    undefined
  )
  const [balance, setBalance] = useState<{
    prev: number | null
    current: number | null
  }>({
    prev: null,
    current: null,
  })
  const [showModal, setShowModal] = useState(false)
  const [tariff, setTariff] = useState<string>("")
  const [username, setUsername] = useState<{
    prev: string
    current: string
  }>({
    prev: "",
    current: "",
  })

  useEffect(() => {
    handleRefreshInfo()

    const interval = setInterval(() => {
      handleRefreshInfo()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  const handleRefreshInfo = async () => {
    if (!key) return
    const isValid = await getUserKey(key)

    if (isValid === "invalid" || isValid === "techWorks") {
      logout()
      return
    }

    let _key = isValid
    if (!_key) {
      const item = localStorage.getItem("auth")
      if (item) {
        _key = JSON.parse(item).key
      } else {
        setUserInfo(undefined)
        return
      }
    }
    const data = (await getShortInfo(_key)) as ShortUserInfoType
    setUserInfo(data)

    setUsername((prev) => {
      if (prev.current !== data.username) {
        return { prev: prev.current, current: data.username }
      }

      return prev
    })

    setBalance((prevBalance) => {
      if (prevBalance.current !== data?.balance) {
        return {
          prev: prevBalance.current,
          current: data?.balance,
        }
      }
      return prevBalance
    })
    if (tariff !== data?.tariff?.name && data?.tariff) {
      if (tariff !== "") {
        await handleRefresh()
      }
      setTariff(data.tariff.name)
    }
  }

  useEffect(() => {
    if (username.current !== username.prev) return
    if (balance?.current === null || balance?.prev === null) return
    if (balance.current > balance.prev) setShowModal(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance.current, balance.prev])

  return (
    <UserInfoContext.Provider
      value={{ userInfo, balance, showModal, setShowModal, handleRefreshInfo }}
    >
      {children}
    </UserInfoContext.Provider>
  )
}

export default UserInfoProvider
