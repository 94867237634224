import type {
  NotificationCenterItem,
  UseNotificationCenter,
} from "react-toastify/addons/use-notification-center"
import { Check, Archive } from "react-feather"

import styles from "./ItemActions.module.scss"

import { PulsatingDot } from "./PulsatingDot"

interface Props
  extends Pick<UseNotificationCenter<{}>, "markAsRead" | "remove"> {
  notification: NotificationCenterItem
}

export function ItemActions({ notification, markAsRead, remove }: Props) {
  return (
    <div className={styles.wrapper}>
      {notification.read ? (
        <Check color="green" />
      ) : (
        <button
          className={styles.button}
          title="Mark as read"
          onClick={() => {
            markAsRead(notification.id)
          }}
        >
          <PulsatingDot />
        </button>
      )}
      <button
        className={styles.button}
        onClick={() => remove(notification.id)}
        title="Archive"
      >
        <Archive color="#666" />
      </button>
    </div>
  )
}
