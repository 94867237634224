import React, { useContext, useEffect, useState } from "react"
import styles from "./BalanceModal.module.scss"
import ok from "../../assets/ok.svg"
import { ThemeContext } from "../../context/ThemeContext"

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  oldValue?: number
  newValue?: number
}

const BalanceModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  oldValue,
  newValue,
}) => {
  const [modalOpen, setModalOpen] = useState(isOpen)
  const [currentBalance, setCurrentBalance] = useState(newValue)
  const [prevBalance, setPrevBalance] = useState(newValue)

  const { theme } = useContext(ThemeContext)

  useEffect(() => {
    setModalOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    setCurrentBalance(newValue)
  }, [newValue])

  useEffect(() => {
    setPrevBalance(oldValue)
  }, [oldValue])

  const closeModal = () => {
    setModalOpen(false)
    onClose()
  }

  if (!modalOpen) return null

  return (
    <div className={`${styles.overlay} ${styles[theme]}`}>
      <div className={styles.modal}>
        <div className={styles.content}>
          {children}
          <img src={ok} alt="ok" />
          <span className={styles.header}>
            Your balance has been replenished! 🪙
          </span>
          <div>
            {`$${prevBalance}`} <span aria-label="arrow">→</span>{" "}
            <span className={styles.newPrice}>{`$${currentBalance}`}</span>
          </div>
          <button className={styles.okButton} onClick={closeModal}>
            OK <span>👌</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default BalanceModal
