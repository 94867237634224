import React, { useContext, useState } from "react"
import classes from "./NavBar.module.scss"
import { ThemeContext } from "../../../context/ThemeContext"
import NavItem from "./NavItem/NavItem"
import { useTranslation } from "react-i18next"
import SwitchThemeButton from "../SwitchThemeButton/SwitchThemeButton"
import LanguageMenu from "../LanguageMenu/LanguageMenu"
import logo from "../../../assets/logo.png"
import { NotificationCenter } from "../NotificationsCenter/NotificationsCenter"
import { AuthContext } from "../../../context/AuthContext"
import ModalWin from "../../ModalWin/ModalWin"

const NavBar = () => {
  const { theme, setTheme } = useContext(ThemeContext)
  const { t } = useTranslation()
  const { logout } = useContext(AuthContext)

  const [showNavbar, setShowNavbar] = useState(false)
  const [activeTab, setActiveTab] = useState("home")

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }
    const [isModalOpen, setIsModalOpen] = useState(true); // Установите значение true, чтобы модальное окно открывалось при входе на страницу

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <nav className={`${classes[theme]} ${classes.navbar}`}>
        <ModalWin isOpen={isModalOpen} closeModal={closeModal} />
      <div className={classes.logoContainer}>
        DDG STRESSER <img className={classes.logo} src={logo} alt="logo" />
      </div>
      <div className={`${classes.container} ${classes.text}`}>
        <div className={classes.mobileMenus}>
          <LanguageMenu />
          <SwitchThemeButton
            onClick={() =>
              setTheme && setTheme(theme === "light" ? "dark" : "light")
            }
          />
          <div onClick={logout} className={classes.logout} />
          <NotificationCenter />
        </div>
        <div className={classes["menu-icon"]} onClick={handleShowNavbar}>
          <div className={`${classes.icon} ${classes.coloredMenu}`} />
        </div>
        <div
          className={`${classes["nav-elements"]}  ${
            showNavbar && classes.active
          }`}
        >
          <div className={classes.wrapper}>
            <ul id="links">
              <NavItem
                id="home"
                name={t("home")}
                className="home"
                link={`/home`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
              <NavItem
                id="balance"
                name={t("balance")}
                className="balance"
                link={`/balance`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
              <NavItem
                id="tariffs"
                name={t("tariffs")}
                className="tariffs"
                link={`/tariffs`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
              <NavItem
                id="hub"
                name={t("attackL4L7")}
                className="attack"
                link={`/layer`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
              <NavItem
                id="telegram"
                name={t("attackTelegram")}
                className="attackTelegram"
                link={`/attackTelegram`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
              <NavItem
                id="monitoring"
                name={t("monitoring")}
                className="monitoring"
                link={`/monitoring`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
              <NavItem
                id="faq"
                name={t("help")}
                className="faq"
                link={`/faq`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
              <NavItem
                id="contacts"
                name={t("contacts")}
                className="contacts"
                link={`/contacts`}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowNavbar={setShowNavbar}
              />
            </ul>
          </div>
          <div className={classes.menus}>
            <LanguageMenu />
            <SwitchThemeButton
              onClick={() =>
                setTheme && setTheme(theme === "light" ? "dark" : "light")
              }
            />
            <div onClick={logout} className={classes.logout} />
            <NotificationCenter />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
