export const WEBAPP_KEY = "bVTsSEu5sLRe7qSUpAtogZAgXBKdUZ5Cwr6xMGcnDuZMcVCGS9";
export const URL = "https://api.ddgstresser.com/";
export const MAX_RATE = 64;
export const MIN = 1;
export const MAX_PORT = 65535;

export const IP_PATTERN = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/gm;

export const PORT_PATTERN =
  /^([1-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/gm;

export const HOST_PATTERN =
  /^(?:(?:https?|ftp):\/\/)?(?:[\w-]+\.)+[a-zA-Z]{2,}(?:\/[\w-]*)*|([0-9]{1,3}\.){3}[0-9]{1,3}$/gi;

export const POST_PATTERN = /([a-zA-Z0-9]+=[a-zA-Z0-9]+)(?:$|&)/g;

export const DIGITS_AND_DOTS = /^[0-9.]+$/;

export const DIGITS = /^[0-9]+$/;
