import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from "react"
import { useNavigate } from "react-router-dom"
import { UserDataContext } from "./UserDataContext"
import { UserInfoContext } from "./UserInfoContext"
import { getUserKey } from "../services"

interface AuthContextType {
  user: string
  login: (value: string) => void
  logout: () => void
  isAuthenticated: boolean
}

export const AuthContext = createContext<AuthContextType>({
  user: "",
  login: (value: string) => {},
  logout: () => {},
  isAuthenticated: false,
})

interface AuthProviderProps {
  children: ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { handleRefresh, setKey, key } = useContext(UserDataContext)
  const { handleRefreshInfo } = useContext(UserInfoContext)
  const navigate = useNavigate()

  const [user, setUser] = useState<string>("")
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  useEffect(() => {
    const checkKey = async () => {
      const keyJSON = localStorage.getItem("auth")
      const parsedKey = keyJSON ? JSON.parse(keyJSON).key : "invalid"
      const _key = key ? key : parsedKey
      if (_key) {
        const isValid = await getUserKey(_key)
        if (isValid !== "invalid" && isValid !== "techWorks") {
          setIsAuthenticated(true)
        } else {
          logout()
        }
      }
    }

    checkKey()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = async (userData: string) => {
    const _key = await getUserKey(userData)
    setKey(_key)
    if (_key !== "invalid" && _key !== "techWorks") {
      setIsAuthenticated(true)
      setUser(_key)
      localStorage.removeItem("auth")
      localStorage.setItem("auth", JSON.stringify({ key: _key }))
      handleRefresh()
      handleRefreshInfo()
    }
  }

  const logout = () => {
    setUser("")
    setKey("")
    setIsAuthenticated(false)
    localStorage.removeItem("auth")
    navigate("/")
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}
