import React, { useContext, useEffect } from "react"
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom"
import { AuthContext } from "./context/AuthContext"

const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"))
const Home = React.lazy(() => import("./pages/Home/Home"))
const Hub = React.lazy(() => import("./pages/Hub/Hub"))
const FAQ = React.lazy(() => import("./pages/FAQ/FAQ"))
const HelpDesc = React.lazy(() => import("./pages/Helpdesc/Helpdesc"))
const Contacts = React.lazy(() => import("./pages/Contacts/Contacts"))
const AttackTelegram = React.lazy(
  () => import("./pages/AttackTelegram/AttackTelegram")
)
const Monitoring = React.lazy(() => import("./pages/Monitoring/Monitoring"))
const Balance = React.lazy(() => import("./pages/Balance/Balance"))
const Tariffs = React.lazy(() => import("./pages/Tariffs/Tariffs"))
const StartPage = React.lazy(() => import("./pages/StartPage/StartPage"))

const Loading = () => <p>Loading ...</p>

type MainType = {
  hasTariff: boolean
}

const Main = ({ hasTariff }: MainType) => {
  const { isAuthenticated, login } = useContext(AuthContext)
  let [searchParams] = useSearchParams()

  useEffect(() => {
    const k = searchParams.get("key")
    if (k) {
      login(k)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/home" /> : <StartPage />}
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/error" element={<NotFound />} />
        <Route
          path="/layer"
          element={
            isAuthenticated ? <Hub hasTariff={hasTariff} /> : <StartPage />
          }
        />
        <Route
          path="/home"
          element={
            isAuthenticated ? <Home hasTariff={hasTariff} /> : <StartPage />
          }
        />
        <Route
          path="/faq"
          element={isAuthenticated ? <FAQ /> : <StartPage />}
        />
        <Route
          path="/helpdesc"
          element={isAuthenticated ? <HelpDesc /> : <StartPage />}
        />
        <Route
          path="/contacts"
          element={isAuthenticated ? <Contacts /> : <StartPage />}
        />
        <Route
          path="/attackTelegram"
          element={
            isAuthenticated ? (
              <AttackTelegram hasTariff={hasTariff} />
            ) : (
              <StartPage />
            )
          }
        />
        <Route
          path="/monitoring"
          element={
            isAuthenticated ? (
              <Monitoring hasTariff={hasTariff} />
            ) : (
              <StartPage />
            )
          }
        />
        <Route
          path="/balance"
          element={isAuthenticated ? <Balance /> : <StartPage />}
        />
        <Route
          path="/tariffs"
          element={isAuthenticated ? <Tariffs /> : <StartPage />}
        />
      </Routes>
    </React.Suspense>
  )
}
export default Main
