import React, { useContext } from "react"
import classes from "./SwitchThemeButton.module.scss"
import { ThemeContext } from "../../../context/ThemeContext"
import { SwitchThemeButtonType } from "./types"
import sun from "../../../assets/sun.svg"
import moon from "../../../assets/moon.svg"

const SwitchThemeButton = ({ onClick }: SwitchThemeButtonType) => {
  const { theme } = useContext(ThemeContext)

  const handleClick = () => {
    onClick && onClick()

    let themeIcon = document.getElementById("theme-icon")
    setTimeout(() => {
      themeIcon && themeIcon.classList.remove(classes.change)
    }, 300)
    themeIcon && themeIcon.classList.add(classes.change)
  }

  return (
    <div className={`${classes[theme]}`}>
      <div onClick={handleClick} className={`${classes.themeContainer}`}>
        <img id="theme-icon" src={theme === "light" ? sun : moon} alt="ERR" />
      </div>
    </div>
  )
}

export default SwitchThemeButton
