import React, { useContext, useEffect, useState } from "react"
import classes from "./LanguageMenu.module.scss"
import { ThemeContext } from "../../../context/ThemeContext"
import { chooseLanguage } from "../../../services"
import { UserDataContext } from "../../../context/UserDataContext"
import languagesDark from "../../../assets/languages.svg"
import languagesLight from "../../../assets/languages-light.svg"
import { useTranslation } from "react-i18next"
import en from "../../../assets/en.svg"
import ru from "../../../assets/ru.svg"
import zh from "../../../assets/ch.svg"
type LanguageOption = {
  value: string
  label: string
  image: string
}

const LanguageSelector = () => {
  const { key, handleRefresh, userData } = useContext(UserDataContext)
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const [width, setWidth] = useState(0)

  const languageOptions: LanguageOption[] = [
    { value: "en", label: t("english"), image: en },
    { value: "ru", label: t("russian"), image: ru },
    { value: "zh", label: t("chinese"), image: zh },
  ]
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0])
  const [isOpen, setIsOpen] = useState(false)

  const { theme } = useContext(ThemeContext)

  useEffect(() => {
    setWidth(window?.innerWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  useEffect(() => {
    if (userData) {
      const defaultLanguage = languageOptions.find(
        (l) => l.value === userData.language
      )
      setSelectedLanguage(
        defaultLanguage ? defaultLanguage : languageOptions[0]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLanguageChange = async (option: LanguageOption) => {
    setSelectedLanguage(option)
    i18n.changeLanguage(option.value)
    if (key) {
      await chooseLanguage(key, option.value)
      await handleRefresh()
    }
    setIsOpen(false)
  }

  return (
    <div className={`${classes.languageSelector} ${classes[theme]}`}>
      <div
        className={classes.languageContainer}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={theme === "light" ? languagesLight : languagesDark}
          alt="Languages"
        />
      </div>
      {isOpen && (
        <div className={classes.languageMenu}>
          {languageOptions.map((option) => (
            <div
              key={option.value}
              className={`${classes.languageOption} ${
                option.value === selectedLanguage.value &&
                classes.selectedLanguage
              }`}
              onClick={() => handleLanguageChange(option)}
            >
              <img
                className={classes.flagImage}
                src={option.image}
                alt={option.label}
              />
              {width > 1300 ? (
                <span>{option.label}</span>
              ) : (
                <span className={classes.mobileLabel}>{option.value}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default LanguageSelector
