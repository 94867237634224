import React, { createContext, useContext, useEffect, useState } from "react"
import {
  UserDataProviderType,
  UserDataContextType,
  ActiveConcurrentType,
} from "./types"
import { getUserData } from "../services"
import {
  ActiveTabType,
  AttackEntity,
  MethodType,
  UserData,
} from "../types/Entities"
import { isObjectEmpty } from "../utils"
import { AuthContext } from "./AuthContext"

export const UserDataContext = createContext<UserDataContextType>({
  key: undefined,
  userData: undefined,
  activeConcurrents: undefined,
  activeAll: undefined,
  error: undefined,
  noConcurrents: false,
  activeTab: "4",
  activeMethod: {} as MethodType,
  hasNoAttacks: false,
  myAttacks: [],
  allAttacks: [],
  setActiveMethod: () => {},
  setActiveTab: () => {},
  setNoConcurrents: () => {},
  handleRefresh: () => {},
  setKey: () => {},
})

export const UserDataProvider = ({ children }: UserDataProviderType) => {
  const { logout } = useContext(AuthContext)
  const [userData, setUserData] = useState<UserData>()
  const [myAttacks, setMyAttacks] = useState<AttackEntity[]>([])
  const [allAttacks, setAllAttacks] = useState<AttackEntity[]>([])
  const [key, setKey] = useState<string>()
  const [activeConcurrents, setActiveConcurrents] =
    useState<ActiveConcurrentType[]>()
  const [activeAll, setActiveAll] = useState<number>()
  const [error, setError] = useState<Error>()
  const [noConcurrents, setNoConcurrents] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<ActiveTabType>(
    isObjectEmpty(userData?.layers[4]) ? "7" : "4"
  )
  const [activeMethod, setActiveMethod] = useState<MethodType>(
    Object.values(userData ? userData?.layers[activeTab] : {})[0]
  )
  const [hasNoAttacks, setHasNoAttacks] = useState(false)

  const fetchData = async () => {
    if (!key) return
    if (key === "invalid" || key === "techWorks") {
      logout()
      return
    }

    const data: UserData = await getUserData(key, setError)
    if (!data) return
    setUserData(data)

    const attacks = data?.attacks?.filter((attack) => attack.init?.is_my)
    const reversed = attacks.reverse()
    const notMy = data?.attacks?.filter((attack) => !attack.init?.is_my)
    if (myAttacks !== reversed) {
      setMyAttacks(reversed)
    }
    const all = [...reversed, ...notMy]
    if (allAttacks !== all) {
      setAllAttacks(all)
    }

    if (!attacks) return

    const activeAttacks = attacks.map((attack) => {
      return attack.concurrents
    })
    const active = activeAttacks?.length
      ? activeAttacks?.reduce((a, b) => a + b)
      : 0
    if (JSON.stringify(activeAll) !== JSON.stringify(active)) {
      setActiveAll(active)
    }

    const res = Object.values(
      attacks.reduce((groups: any, current) => {
        if (!groups[current.method]) {
          groups[current.method] = []
        }
        groups[current.method].push(current)
        return groups
      }, {})
    ).map((g: any) => {
      if (g.length === 1)
        return { method: g[0].concurrents, started: g[0].concurrents }
      return g.reduce((a: any, b: any) => ({
        method: a.method,
        started: a.concurrents + b.concurrents,
      }))
    })
    const activeConc = res as ActiveConcurrentType[]
    if (JSON.stringify(activeConc) !== JSON.stringify(activeConcurrents)) {
      setActiveConcurrents(activeConc)
    }
  }

  useEffect(() => {
    if (key) {
      // fetchKeyData(key)
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  useEffect(() => {
    if (!key) {
      let _key = ""
      const item = localStorage.getItem("auth")
      if (item) {
        _key = JSON.parse(item).key
        setKey(_key)
      } else {
        setUserData(undefined)
        return
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setHasNoAttacks(userData?.attacks?.length === 0)
  }, [userData?.attacks])

  const handleRefresh = async () => {
    await fetchData()
  }

  return (
    <UserDataContext.Provider
      value={{
        userData,
        key,
        error,
        activeConcurrents,
        activeAll,
        myAttacks,
        allAttacks,
        handleRefresh,
        activeMethod,
        setActiveMethod,
        noConcurrents,
        setNoConcurrents,
        activeTab,
        setActiveTab,
        hasNoAttacks,
        setKey,
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}
