import { isIPv4 } from "is-ip";
import { URL, WEBAPP_KEY } from "./constants";
import { DataType, NodeData } from "./pages/Monitoring/Monitoring";
import { OperationsType, SendAttackEntity } from "./types/Entities";
import { notifyError } from "./utils";

export const getUserData = async (
  userKey: string,
  setError?: (err: Error) => void
) => {
  const endpoint = `${URL}api/user/info?key=${userKey}`;

  const response = await fetchData(endpoint);
  return response;
};

export const restartAttack = async (
  stackId: number,
  userKey: string,
  setError?: (err: Error) => void
) => {
  let endpoint = `${URL}api/attack/restart?key=${userKey}&stack_id=${stackId}`;
  const response = await fetchData(endpoint, setError);
  return response;
};

export const getUserKey = async (key: string) => {
  const isKeyValid = await validateUserKey(key);
  if (isKeyValid?.can_use && key && !isKeyValid?.tech_works) {
    return key;
  } else if (isKeyValid?.tech_works) {
    return "techWorks";
  }
  return "invalid";
};

export const validateUserKey = async (key?: string) => {
  if (!key) return;
  let endpoint = `${URL}api/validation/key?key=${key}`;
  const response = await fetchData(endpoint);
  return response;
};

export const startAttack = async (
  attack: SendAttackEntity,
  theme: string,
  errorMessage: string,
  custom_id?: string,
  key?: string
) => {
  if (!attack || !key) return;
  const layer7 =
    attack.layer === "7" &&
    `&mode=${attack.mode}&rate=${attack.rate}&postdata=${attack.postData}`;

  const geo = `${
    attack.geolocation &&
    attack.geolocation !== "Worldwide" &&
    attack.geolocation !== "ww"
      ? `&geo=${attack.geolocation}`
      : ""
  }`;

  let _host = attack.host;

  if (!isIPv4(attack.host)) {
    _host =
      attack.host.startsWith("http") || attack.host.startsWith("https")
        ? attack.host
        : `https://${attack.host}`;
  }

  const endpoint = `${URL}api/attack/start?key=${key}&webapp_key=${WEBAPP_KEY}&host=${_host}&port=${attack.port}&method=${attack.method}&time=${attack.time}&concurrents=${attack.concurrents}&custom_id=${custom_id}${geo}${layer7}`;
  const response = await fetchDataWithTimeout(
    endpoint,
    theme,
    errorMessage,
    attack.concurrents && attack.concurrents * 10000
  );
  return response;
};

export const stopAttacks = async (
  userKey: string,
  setError?: (err: Error) => void
) => {
  const endpoint = `${URL}api/attack/stop?key=${userKey}&stop_all=1`;

  const response = await fetchData(endpoint, setError);
  return response;
};

export const stopAttack = async (
  stackId: number,
  userKey: string,
  stopAll?: boolean,
  setError?: (err: Error) => void
) => {
  let endpoint = `${URL}api/attack/stop?key=${userKey}&stack_id=${stackId}`;
  if (stopAll) {
    endpoint += "&stop_all=1";
  }

  const response = await fetchData(endpoint, setError);
  return response;
};

export const getDetails = async () => {
  let endpoint = `${URL}api/stat/details`;
  const response = await fetchData(endpoint);
  return response;
};

export const getNews = async () => {
  let endpoint = `${URL}api/stat/news`;
  const response = await fetchData(endpoint);
  return response;
};

export const getHelpdesc = async () => {
  let endpoint = `${URL}api/stat/helpdesc`;
  const response = await fetchData(endpoint);
  return response;
};

export const getWallet = async (
  currency?: string,
  network?: string,
  key?: string
) => {
  let endpoint = `${URL}api/user/wallet?key=${key}&currency=${currency}&network=${network}`;
  const response = await fetchData(endpoint);
  return response;
};

export const getShortInfo = async (key?: string) => {
  if (!key || key === "invalid") return;
  let endpoint = `${URL}api/user/info_short?key=${key}`;
  const response = await fetchData(endpoint);
  return response;
};

export const getTariffs = async () => {
  let endpoint = `${URL}api/tariff/available`;
  const response = await fetchData(endpoint);
  return response?.tariffs;
};

export const buyTariff = async (
  newTariff: number,
  key?: string,
  current?: number
) => {
  if (!key) return;
  let endpoint = ``;

  if (current) {
    endpoint = `${URL}api/tariff/buy?key=${key}&tariff_current=${current}&tariff_new=${newTariff}`;
  } else {
    endpoint = `${URL}api/tariff/buy?key=${key}&tariff_new=${newTariff}`;
  }

  const response = await fetchData(endpoint);
  return response;
};

const fetchData = async (endpoint: string, setError?: (err: Error) => void) => {
  try {
    const response = await fetch(endpoint, {
      method: "GET",
      cache: "no-store",
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    // setError && setError(new Error(er ? (error as string) : "Unexpected error"))
  }
};

const fetchDataWithTimeout = async (
  endpoint: string,
  theme: string,
  errorMessage: string,
  timeout = 10000
) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(endpoint, {
      signal: controller.signal,
    });
    const json = await response.json();
    clearTimeout(timeoutId);
    return json;
  } catch (error) {
    if (error instanceof DOMException && error.name === "AbortError") {
      notifyError(theme, "Request timed out");
    } else {
      notifyError(theme, errorMessage);
    }
  }
};

const fetchDataCheckHost = async (endpoint: string) => {
  try {
    const response = await fetch(endpoint, {
      headers: {
        Accept: "application/json",
      },
    });
    const json = await response.json();
    return json;
  } catch (error) {
    const er = error as Error;
    console.error(er.message);
  }
};

export const validateHost = async (
  host: string,
  setError?: (err: Error) => void
) => {
  const endpoint = `${URL}api/validation/target?value=${host}`;
  const response = await fetchData(endpoint, setError);
  return response;
};

export const chooseLanguage = async (key: string, lang: string) => {
  const endpoint = `${URL}api/user/edit/language?key=${key}&language=${lang}`;
  const response = await fetchData(endpoint);
  return response;
};

export const pingHost = async (host: string): Promise<DataType> => {
  const endpoint = `https://api.ddgstresser.com/api/monitoring/check?type=ping?host=${host}`;
  const response = await fetchDataCheckHost(endpoint);
  return response;
};

export const checkHttp = async (host: string) => {
  const endpoint = `https://api.ddgstresser.com/api/monitoring/check?type=http?host=${host}`;
  const response = await fetchDataCheckHost(endpoint);
  return response;
};

export const checkTcp = async (host: string) => {
  const endpoint = `https://api.ddgstresser.com/api/monitoring/check?type=tcp?host=${host}`;
  const response = await fetchDataCheckHost(endpoint);
  return response;
};

export const checkUdp = async (host: string) => {
  const endpoint = `https://api.ddgstresser.com/api/monitoring/check?type=udp?host=${host}`;
  const response = await fetchDataCheckHost(endpoint);
  return response;
};

export const checkDns = async (host: string) => {
  const endpoint = `https://api.ddgstresser.com/api/monitoring/check?type=dns?host=${host}`;
  const response = await fetchDataCheckHost(endpoint);
  return response;
};

export const getCheckRes = async (id: string): Promise<NodeData> => {
  const endpointResult = `https://api.ddgstresser.com/api/monitoring/result?id=${id}`;
  const response = await fetchDataCheckHost(endpointResult);
  return response;
};

export const getOperationsInfo = async (): Promise<OperationsType> => {
  const endpoint = `https://api.ddgstresser.com/api/stat/operations`;
  const response = await fetchData(endpoint);
  return response;
};

export const getPromocode = async (key: string, code: string) => {
  const endpoint = `https://api.ddgstresser.com/api/tariff/promo?key=${key}&code=${code}`;
  const response = await fetchData(endpoint);
  return response;
};
