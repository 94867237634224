import React, { useEffect } from "react";
import "./ModalWin.css";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";

const ModalWin = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Запретить прокрутку фона при открытом модальном окне
    } else {
      document.body.style.overflow = "auto"; // Разрешить прокрутку фона при закрытом модальном окне
    }
  }, [isOpen]);

  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames={{
        enter: "modal-enter",
        enterActive: "modal-enter-active",
        exit: "modal-exit",
        exitActive: "modal-exit-active",
      }}
      unmountOnExit
    >
      <div className="modal-overlay">
        <div className="modal">
          <span className="close-button" onClick={closeModal}>
            X
          </span>
          <p>
            {t("modalWinTitle")} <br /> <br />
            {t("modalWinDisc")}
            <br /> <br /> {t("modalWinDiscMid")}
            <br /> <br /> {t("modalWinDiscEnd")}
            <br /> <br /> {t("link")}
            <b>
              <a href="https://t.me/+DZS1-A3B2q5iOGM6" target="_blank">
               https://t.me/+DZS1-A3B2q5iOGM6 ✅
              </a>
            </b>
            <br /> <br /> {t("site")}{" "}
            <b>
              <a href="https://link.ddgstresser.com " target="_blank">
               https://link.ddgstresser.com ❗️
              </a>
            </b>
            <br /> <br /> {t("admin")}{" "}
            <b>
              <a href="https://t.me/ddg_post" target="_blank">
                @ddg_post (only telegram)
              </a>
            </b>
            <br /> <br /> {t("modalWinEnd")}
          </p>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ModalWin;
