import React, { useContext } from "react"
import classes from "./Layout.module.scss"
import Main from "../Main"
import NavBar from "../components/UI/NavBar/NavBar"
import { ThemeContext } from "../context/ThemeContext"
import BalanceModal from "../components/BalanceModal/BalanceModal"
import { UserInfoContext } from "../context/UserInfoContext"
import { LayoutType } from "./types"

const Layout = ({ hasTariff }: LayoutType) => {
  const { balance, showModal, setShowModal } = useContext(UserInfoContext)
  const { theme } = useContext(ThemeContext)

  return (
    <div className={`${classes.wrapper} ${classes[theme]}`}>
      <div
        className={`${classes.container} ${classes.background} ${classes.text}`}
      >
        <div className={`${classes.backgroundAnimation}`} />
        <NavBar />
        <div className={classes.wrapperContainer}>
          <BalanceModal
            isOpen={showModal}
            oldValue={balance?.prev ? balance?.prev : 0}
            newValue={balance?.current ? balance?.current : 0}
            onClose={() => {
              setShowModal(false)
            }}
          />
          <Main hasTariff={hasTariff} />
        </div>
      </div>
    </div>
  )
}

export default Layout
