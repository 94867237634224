import React, { createContext, useContext, useEffect, useState } from "react"
import { UserDataContext } from "./UserDataContext"
import { checkHttp, getCheckRes } from "../services"

interface HttpOkContextType {
  times: number
  hostConnection: HostConnectionType[]
  hasCaptcha: boolean
  setHostConnection: React.Dispatch<React.SetStateAction<HostConnectionType[]>>
}

export interface HostConnectionType {
  host: string
  className: string
  percents: string
}

export const HttpOkContext = createContext<HttpOkContextType>({
  times: 0,
  hostConnection: [] as HostConnectionType[],
  hasCaptcha: false,
  setHostConnection: () => {},
})

interface HttpOkProviderProps extends React.HTMLAttributes<HTMLDivElement> {}

const HttpOkProvider: React.FC<HttpOkProviderProps> = ({ children }) => {
  const { userData } = useContext(UserDataContext)

  const [hostConnection, setHostConnection] = useState<HostConnectionType[]>([])
  const [returnCaptcha, setReturnCaptcha] = useState(false)
  const [times, setTimes] = useState(0)

  // useEffect(() => {
  //   if (!userData?.attacks) return
  //   const hosts = userData.attacks.map((attack) => attack.host)
  //   const allHosts = hosts?.filter((value, index, array) => {
  //     return array.indexOf(value) === index
  //   })

  //   if (!allHosts) return
  //   const fetchData = async (host: string) => {
  //     const resHttp = await checkHttp(host)
  //     if (!resHttp) return
  //     await new Promise((resolve) => setTimeout(resolve, 1000))
  //     const response = await getCheckRes(resHttp?.request_id)
  //     if (!response) return

  //     let values = Object.values(response ? response : {})
  //     let nullValue = values.includes(null)
  //     let className = ""
  //     let count = 0
  //     const length = values?.length
  //     let percents = 0

  //     if (values) {
  //       while (nullValue) {
  //         const res = await getCheckRes(resHttp?.request_id)
  //         values = Object.values(res ? res : {})
  //         nullValue = values.includes(null)
  //         await new Promise((resolve) => setTimeout(resolve, 1000))
  //       }

  //       values?.forEach((value) => {
  //         if (value?.[0]?.[0] && (value?.[0]?.[0] as unknown as number) === 1) {
  //           count++
  //         }
  //       })

  //       const hasCaptcha = values?.find((value) =>
  //         value?.[0]?.[3]?.toString().includes("403")
  //       )
  //       setReturnCaptcha(hasCaptcha ? true : false)

  //       percents = (count / length) * 100

  //       if (percents > 90) {
  //         className = "green"
  //       } else if (percents > 60) {
  //         className = "yellow"
  //       } else if (percents > 30) {
  //         className = "orange"
  //       } else {
  //         className = "red"
  //       }
  //     }

  //     setHostConnection((prev) => {
  //       const updatedHostConnection = prev.filter((hc) => hc.host !== host)
  //       return [
  //         ...updatedHostConnection,
  //         {
  //           host,
  //           className,
  //           percents: `${percents ? percents.toFixed(0) : 0}%`,
  //         },
  //       ]
  //     })
  //   }

  //   const updateData = async () => {
  //     if (allHosts) {
  //       await Promise.all(allHosts.map(fetchData))
  //     }

  //     setTimes((prev) => prev + 1)
  //   }

  //   updateData()

  //   const interval = setInterval(() => {
  //     updateData()
  //   }, 60000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [userData?.attacks])

  return (
    <HttpOkContext.Provider
      value={{
        times,
        hostConnection,
        hasCaptcha: returnCaptcha,
        setHostConnection,
      }}
    >
      {children}
    </HttpOkContext.Provider>
  )
}

export default HttpOkProvider
