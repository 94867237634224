import React, { useContext, useEffect } from "react"
import classes from "./App.module.scss"
import { ThemeContext } from "./context/ThemeContext"
import spinner from "./assets/spinner.gif"
import { UserDataContext } from "./context/UserDataContext"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import i18n from "i18next"
import { useTranslation, initReactI18next, Trans } from "react-i18next"
import { translates } from "./translates"
import Layout from "./Layouts/Layout"
import Button from "./components/UI/Button/Button"
import { NavLink } from "react-router-dom"
import tech from "./assets/tech.svg"
import "./styles/Toast.scss"
import Main from "./Main"
import { AuthContext } from "./context/AuthContext"

i18n.use(initReactI18next).init({
  resources: translates,
  lng: "en",
  fallbackLng: "en",
})

function App() {
  const { theme } = useContext(ThemeContext)
  const { userData, key } = useContext(UserDataContext)
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    if (!userData?.language) return
    i18n.changeLanguage(userData?.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.language])

  return (
    <div className={`${classes[theme]} ${classes.container}`}>
      {key !== "invalid" && key !== "techWorks" ? (
        <>
          {!isAuthenticated ? (
            <Main hasTariff={userData?.tariff_name ? true : false} />
          ) : (
            <>
              {userData ? (
                <Layout hasTariff={userData?.tariff_name ? true : false} />
              ) : (
                <div className={`${classes.loader}`}>
                  <img src={spinner} alt="spinner" />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {key === "techWorks" && (
            <div className={`${classes.loader} ${classes.noTariffLoader}`}>
              <img src={spinner} alt="spinner" />
              <div className={classes.noTariffContainer}>
                <div className={classes.techContainer}>
                  <img src={tech} className={classes.tech} alt="techWorks" />
                  <Trans t={t}>Tech Works</Trans>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button className={classes.buttonApp}>
                    <NavLink to="https://ddg.lol/">
                      <Trans t={t}>goWebSite</Trans>
                    </NavLink>
                  </Button>
                  <Button className={classes.buttonApp}>
                    <NavLink to="https://t.me/ddg_stresser_bot">
                      <Trans t={t}>goBot</Trans>
                    </NavLink>
                  </Button>
                </div>
              </div>
            </div>
          )}
          {key === "invalid" ? (
            <div className={`${classes.loader} ${classes.noTariffLoader}`}>
              <img src={spinner} alt="spinner" />
              <div className={classes.noTariffContainer}>
                <span>
                  <Trans t={t}>invalidKey</Trans>
                </span>
                <div className={classes.buttonWrapper}>
                  <Button className={classes.buttonApp}>
                    <NavLink to="https://ddg.lol/">
                      <Trans t={t}>goWebSite</Trans>
                    </NavLink>
                  </Button>
                  <Button className={classes.buttonApp}>
                    <NavLink to="https://t.me/ddg_stresser_bot">
                      <Trans t={t}>goBot</Trans>
                    </NavLink>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {key !== "techWorks" && (
                <>
                  {userData ? (
                    <Layout hasTariff={userData?.tariff_name ? true : false} />
                  ) : (
                    <div className={`${classes.loader}`}>
                      <img src={spinner} alt="spinner" />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <ToastContainer newestOnTop hideProgressBar={false} autoClose={3000} />
    </div>
  )
}

export default App
