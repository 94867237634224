export const translates = {
  en: {
    translation: {
      sendAttack: "Start operation",
      showAttacks: "Show Attacks",
      attacks: "Attacks",
      showHub: "Show Hub",
      attackMethod: "Attack Method",
      stopConcurrent: "Stop Concurrent",
      stopAllStack: "Stop All Stack",
      stopAttacks: "Stop my operations",
      cantStop: "Unable to stop the operation",
      restarting: "Restarting...",
      refreshed: "Refreshed successfully",
      refreshing: "Refreshing...",
      failedRestart: "Failed to retry the action",
      mode: "Mode",
      hostTranslate: "Host",
      modeTranslate: "Method",
      work: "Work",
      timeTranslate: "Time",
      actions: "Actions",
      noAttackData: "No data. Start attack please",
      concurrentsExceed: "Request of active concurrents exceed. You have:",
      canStart: "You can start",
      concurrentsMany: "concurrents",
      concurrentsTranslate: "Concurrents",
      portTranslate: "Port",
      rateTranslate: "Rate",
      postDataTranslate: "Post Data",
      attackDetails: "Attack Details",
      target: "Target",
      started: "Started",
      notStarted: "Not Started",
      timeout: "Timeout",
      goWebSite: "Go to Web Site",
      goBot: "Go to Bot",
      noTariff: "No Tariff :(",
      news: "News",
      registeredUsers: "Registered Users",
      daysOnline: "Days Online",
      testsMade: "Tests Made",
      methods: "Methods",
      method: "Method",
      home: "Home",
      attackL4L7: "Attack L4/L7",
      attackTelegram: "Attack Telegram",
      helpdesc: "Helpdesc",
      contacts: "Contacts",
      wrongKey: "You may have entered your merch-key incorrectly",
      couldntStartAttack:
        "We couldn't complete the operation. Please try again later",
      noRequests: "You have no requests for today",
      attackStarted: "Starting operation...",
      noConcurrents:
        "No concurrents were started. Servers are overloaded. Try again later",
      notAllConcurrents: "Attack was started. But only ",
      notAllConcurrentsPt2: " concurrents. Servers are overloaded",
      attackSuccess: "Operation started Successfully",
      somethingWrong: "Something went wrong 🙁",
      profile: "Profile",
      changeAccount: "Change profile",
      username: "Username",
      balance: "Balance",
      tariff: "Tariff",
      by: "By: ",
      availableMethods: "Available Methods",
      soon: "Coming soon...",
      whatIsStresser: "What is DDG-Stresser?",
      whatIsStresserContent:
        "DDG-Stresser is a telegram bot that allows you to send attacks using our powerful DDoS network with the most advanced methods and the highest power.",
      freeMethods: "Are there any free methods?",
      freeMethodsContent:
        "Yes! there are basic Level 4 and level 7 methods that you can use for free for 120 seconds, keep in mind that the power is minimal and in no way comparable to our paid methods.",
      support: "How can I contact customer support?",
      weHave: "We have a - ",
      telegramAdmin: "telegram admin account",
      power: "Power",
      powerContent:
        "Unfortunately, there is no accurate way to measure the real effectiveness of a DDoS attack, and this is the main thing. Power estimates based on Gps and RQPS are completely useless and should be ignored. We can only say that we offer the most effective methods, making it stable, reliable and long-term. Whether an attack is effective or not has very little to do with the amount of traffic being sent (Gbps or Rqps) and more to do with where that traffic is being sent, or the type of traffic itself. Some defenses may block 100 Gbit/s attacks from one method and decrease from 10 Gbit/s from another method. Some methods can send 100,000 RQPS on a graph, but are useless against real targets or any JS protection. We try to offer effective reliable solutions, not numbers on a graph.",
      whatIsProtection: "What protection can it bypass?",
      protectionContent:
        "Currently, our Layer 7 can bypass Cloudflare UAM and most other JavaScript and cookie-based protections, we also offer a high degree of customization that allows you to change post data, send custom cookies, attack origin to bypass geoblock, ip requests to bypass ratelimit, referrer and other useful settings. In the near future, we plan to add support for bypassing captcha and additional security features. Our level 4 offers a variety of methods, some of which are new and can bypass several protections.",
      privacy: "User privacy and security",
      privacyContent:
        "We are proud to be one of the most privacy-respecting stress testing services, as well as one of the most secure. We do not keep IP address logs, do not request email, and do not invade your privacy with the help of tracking systems. Passwords are hashed and salted using blowfish, and everything has been coded with privacy and security in mind. In addition, we have never been hacked and have not suffered from any leaks.",
      contactUs: "Contact Us!",
      sup: "Support",
      willHelp: "We will help you anytime",
      telegramBot: "Telegram Bot",
      way: "Mobile way to use our service",
      bot: "Join us!",
      ourDesc:
        "DDG-Stresser is a telegram bot that allows you to send attacks using our powerful DDoS network with the most advanced methods and the highest power. Using our powerful DDoS network with advanced methods and high power. We offer the most effective methods that can bypass several resource protections.",
      stoppingAttack: "Stopping Attack...",
      stoppingAttacks: "Stopping Attacks...",
      attackStopped: "Operation was stopped successfully",
      attacksStopped: "Operations were stopped successfully",
      readMore: "read more",
      readLess: "read less",
      blacklisted:
        "Sorry, you are trying to enter address which is in a blacklist",
      inactive: "Inactive",
      invalidAttackEntity:
        "You are trying to enter invalid data, please check it and try again",
      monitoring: "Monitoring",
      ping: "Ping Server",
      pingTitle:
        "Check the reachability of a host with Ping from USA, Canada, Europe and other places.",
      pingDesc:
        "No need to ping manually website/server from different places. Ping hostname in automatic mode with our online service from many places around the world in one click. Ping the world and be happy!",
      examples: "Examples",
      location: "Location",
      result: "Result",
      rtt: "rtt min / max / avg",
      ip: "IP address",
      check: "Check Website",
      checkTitle: "Quick check any URL! HTTP or HTTPS with any ports.",
      checkDesc:
        "Check website availability and performance with responses from many servers in various countries. This is website monitoring in easy way!",
      timeHeader: "Time",
      codeHeader: "Code",
      tcp: "TCP connect",
      tcpTitle:
        "Make TCP connection to any port of any IP or website from different places.",
      tcpDesc:
        "Check port status of server. For example you could check a new SSH port of your server for availability from many countries. Website monitoring or any other services monitoring with this tool are able to make system administrator's day.",
      udp: "UDP connect",
      udpTitle:
        "Check UDP connection to any port of any IP or website from different places.",
      udpDesc:
        "For example you could check a DNS or game server for availability from many countries.",
      dnsTitle:
        "DNS checking is very useful for monitoring domain of any website.",
      dnsDesc:
        "It gets an entry for hostname or IP from  nameservers in different countries at one time.",
      connection: "Connection",
      invalidKey:
        "You entered invalid key, please check the key you entered and try again",
      mayReturnCaptcha: "May return a captcha",
      topUpBalance: "Top up your balance",
      coinText:
        "Select the cryptocurrencies you would like to top up your balance",
      isChosen: "is chosen now",
      chooseNetwork: "Choose a network",
      yourWallet: "Your current wallet is",
      attention: "Please be careful making the transaction!",
      attentionCrypt: " Be sure to check the cryptocurrency",
      attentionNetwork: "and the network",
      attentionMoney:
        "or your money will be lost. The minimal sum of the transaction is 5$",
      tariffs: "Tariffs",
      concurrentsBig: "Concurrents",
      secondsSmall: "seconds",
      max: "Max. ",
      purchasedBig: "Purchased",
      needBig: "Need: ",
      cheaperBig: "Cheaper",
      buyBig: "Buy",
      topUpBig: "Top Up",
      boughtBig: "Bought",
      buyWarning:
        "Are you sure you want to buy the tariff? It will take off money from your balance!",
      yourBalance: "Your balance:",
      yes: "Yes",
      cancel: "Cancel",
      attacksPerBig: "Attacks per day",
      networkBig: "Network",
      enabledVip: "VIP Network:",
      days: "d",
      detailsBig: "Details",
      buyTariff:
        "You have no tariff to access this page. Please buy tariff to continue",
      english: "English",
      russian: "Russian",
      chinese: "Chinese",
      unavailable: "Unavailable",
      loading: "Choose coin",
      help: "Help",
      help2: "Help",
      enterQuery: "Enter query",
      of: "of",
      showMy: "Show My",
      showAll: "Show All",
      mine: "mine",
      dataCopied: "Data was successfully copied to the form",
      waitingResponse: "The task is waiting for server confirmation",
      enterPromo: "Enter promocode",
      applied: "Promocode applied",
      empty: "Your queue is empty!",
      clearAll: "Clear all",
      markAsRead: "Mark all as read",
      onlyUnread: "Only show unread",
      geolocation: "Geolocation",
      geolocationTip: "Source of outgoing requests for the target resource",
      presets: "Presets",
      resetPresets: "Reset Presets",
      preset: "Preset",
      from: "from",
      wrongHostPreset: "You are trying to save preset with wrong host",
      apply: "Apply",
      savedPresets: "Preset was successfully saved!",
      clearedPresets: "Presets were successfully cleared!",
      noPresets: "You have no presets saved",
      emptyPresets: "You have no presets, please create a new one",
      networkLoad: "Network load",
      serverConnectionError: "Server connection error",
      startingBot: "Bot",
      tgChannel: "Telegram Channel",
      aboutUs: "About Us",
      enter: "Enter",
      descStarting: "Performance evaluation and user-friendly interface",
      approach: "OUR APPROACH TO YOUR TASK",
      aboutStarting:
        "Using our powerful DDoS network with advanced techniques and high power. We offer the most effective methods that can bypass multiple resource protections.",
      titleAbout: "Advantages DDG STRESSER",
      descAbout:
        "Our custom stressor attack hub allows you to bypass custom DDOS attack blocking rules and help you achieve your goal with more confidence. With our hub, you can use custom settings to bypass any target, even if they have DDOS attack blocking.",
      titlePayment: "Secure Payment",
      descPayment:
        "We process all your cryptocurrency payments with our own nodes to ensure 100% anonymity.",
      securityTitle: "Your security is important to us.",
      securityDesc:
        "Stress tests are run from multiple places and cannot be controlled. Your privacy is safe, no logs are stored and all data is encrypted.",
      launchTitle: "Modern launch methods",
      launchDesc:
        "Our DDoS attack methods are able to bypass the latest security measures, and our engineers are constantly improving them.",
      supportTitle: "Customer support is with you 24/7",
      supportDesc:
        "Our support is here to help you, if you need anything you can contact us.",
      maximum: "Maximum",
      reqDay: "Requests a day",
      planLimit: "Plan limit",
      network: "Network",
      fastEnter: "Fast Enter",
      newAccount: "Enter new account",
      enterInAccount: "Enter account",
      merchKey: "Merch key",
      chooseEnterMethod: "Choose enter method:",
      chooseAccountEnter: "Choose account to enter:",
      register: "Register",

      modalWinTitle: "DDG Stresser / Official Channels ✅",
      modalWinDisc:
        "due to the recent events of telegram channels have been deleted , we will publish links to our current channels so that you are aware of all the news",
      modalWinDiscMid:
        "We also take certain measures in connection with which this situation should no longer arise",
      modalWinDiscEnd:
        "We really appreciate that you stay with us, we will do our best to make this problem last thank you",
      modalWinEnd: "The DDG team is with you ✅",

      link: "The actual link to our telegram channel - ",
      site: "Website where you will be able to see all of our relevant domains, and links to the telegram channels",
      admin: "Administrator -",
    },
  },
  ru: {
    translation: {
      sendAttack: "Запустить задачу",
      showAttacks: "Показать Атаки",
      attacks: "Атаки",
      showHub: "Показать Хаб",
      attackMethod: "Метод атаки",
      stopConcurrent: "Остановить конкурента",
      stopAllStack: "Остановить всех конкурентов",
      stopAttacks: "Остановить мои задачи",
      cantStop: "Невозможно остановить операцию",
      restarting: "Обновляем...",
      refreshing: "Обновляем...",
      refreshed: "Успешно обновлено",
      failedRestart: "Не получилось повторить действие",
      modeTranslate: "Режим",
      hostTranslate: "Хост",
      method: "Метод",
      work: "Активны",
      timeTranslate: "Время",
      actions: "Действия",
      noAttackData: "Нет данных. Запустите, пожалуйста, атаку.",
      concurrentsExceed: "Лимит активных конкурентов исчерпан. У вас:",
      canStart: "Вы можете запустить",
      concurrentsMany: "конкурентов",
      concurrentsTranslate: "Конкуренты",
      portTranslate: "Порт",
      rateTranslate: "Частота",
      postDataTranslate: "Параметры",
      attackDetails: "Детали",
      target: "Цель",
      started: "Начато",
      notStarted: "Не Начато",
      timeout: "Ожидание",
      goWebSite: "На сайт",
      goBot: "Бот",
      noTariff: "Отсутствует тариф :(",
      news: "Новости",
      registeredUsers: "Пользователей",
      daysOnline: "Дней онлайн",
      testsMade: "Сделано тестов",
      methods: "Методов",
      home: "Главная",
      attackL4L7: "Атака L4/L7",
      attackTelegram: "Телегерам атака",
      helpdesc: "Помощь",
      contacts: "Контакты",
      wrongKey: "Вы могли некорректно ввести свой мерч-ключ",
      couldntStartAttack: "Мы не смогли запустить операцию, попробуйте позже.",
      noRequests: "Лимит запросов исчерпан",
      attackStarted: "Операция запускается...",
      noConcurrents:
        "Не было запущено ниодного конкурента. Сервера по данному методу загружены, попробуйте немного позже.",
      notAllConcurrents: "Атака запущена, но только ",
      notAllConcurrentsPt2: " конкурента. Сервера по данному методу загружены.",
      attackSuccess: "Операция успешно запущена",
      somethingWrong: "Что-то пошло не так 🙁",
      profile: "Профиль",
      changeAccount: "Сменить профиль",
      username: "Имя пользователя",
      balance: "Баланс",
      tariff: "Тариф",
      by: "До: ",
      availableMethods: "Доступные методы",
      soon: "В процессе...",
      whatIsStresser: "Что такое DDG-Stresser?",
      whatIsStresserContent:
        "DDG-Stresser-это телеграмм бот, который позволяет отправлять атаки с использованием нашей мощной DDoS-сети с самыми передовыми методами и самой высокой мощностью.",
      freeMethods: "Есть ли бесплатные методы?",
      freeMethodsContent:
        "Да! существуют базовые методы уровня 4 и уровня 7, которые вы можете использовать бесплатно в течение 120 секунд, имейте в виду, что мощность минимальна и никоим образом не сравнима с нашими платными методами.",
      support: "Как я могу связаться со службой поддержки?",
      weHave: "У нас есть - ",
      telegramAdmin: "телеграмм акаунт админа",
      power: "Мощность",
      powerContent:
        "К сожалению, нет точного способа измерить реальную эффективность DDoS-атаки, а это главное. Оценки мощности, основанные на Gbps и RQPS, совершенно бесполезны и должны быть проигнорированы. Мы можем только сказать, что предлагаем наиболее эффективные методы, делая его стабильным, надежным и долгосрочным. Является ли атака эффективной или нет, имеет очень мало общего с количеством отправляемого трафика (Gbps или Rqps) и больше с тем, куда этот трафик отправляется, или с типом самого трафика. Некоторые средства защиты могут блокировать атаки 100 Гбит / с из одного метода и снижаться с 10 Гбит / с из другого метода. Некоторые методы могут отправлять 100 000 RQPS на графике, но бесполезны против реальных целей или любой защиты JS. Мы стараемся предлагать эффективные надежные решения, а не цифры на графике.",
      whatIsProtection: "Какую защиту он может обойти",
      protectionContent:
        "В настоящее время наш уровень 7 может обойти Cloudflare UAM и большинство других защит на основе JavaScript и cookie, мы также предлагаем высокую степень настройки, позволяющую изменять post-данные, отправлять пользовательские файлы cookie, атаковать origin для обхода geoblock, запросы на ip для обхода ratelimit, referrer и другие полезные настройки. В ближайшее время мы планируем добавить поддержку обхода капчи и дополнительные средства защиты. Наш уровень 4 предлагает множество методов, некоторые из которых являются новыми и могут обойти несколько защит.",
      privacy: "Безопасность и конфиндециальность",
      privacyContent:
        "Мы не ведем журналы IP-адресов, не запрашиваем электронную почту и не вторгаемся в вашу частную жизнь с помощью систем слежения. Пароли хэшируются и солятся с помощью blowfish, и все было закодировано с учетом конфиденциальности и безопасности. Кроме того, мы никогда не были взломаны и не страдали от каких-либо утечек.",
      contactUs: "Свяжитесь с нами!",
      sup: "Поддержка",
      willHelp: "Поможем вам в любое время",
      telegramBot: "Телеграм Бот",
      way: "Используй наш сервис с мобильного",
      bot: "Подключайся к нам!",
      ourDesc:
        "DDG-Stresser это телеграмм-бот, который позволяет вам отправлять атаки, используя нашу мощную DDoS-сеть с самыми передовыми методами и самой высокой мощностью. Используя нашу мощную DDoS-сеть с передовыми методами и высокой мощностью. Мы предлагаем самые эффективные методы, которые могут обойти несколько защит ресурсов.",
      stoppingAttack: "Останавливаем атаку...",
      stoppingAttacks: "Останавливаем атаки...",
      attackStopped: "Операция была успешно остановлена",
      attacksStopped: "Операции были успешно остановлены",
      readMore: "подробнее",
      readLess: "свернуть",
      blacklisted: "Извините, вы пытаетесь ввести адрес из блэклиста",
      inactive: "Неактивна",
      invalidAttackEntity:
        "Вы пытаетесь ввести некорректные данные, пожалуйста, перепроверьте их и попробуйте снова",
      monitoring: "Мониторинг",
      ping: "Ping Сервера",
      pingTitle:
        "Проверьте доступность ресурса с помощью Ping из США, Канады, Европы и многих других мест.",
      pingDesc:
        "Теперь не надо производить ping вручную своего веб-сайта или сервера с разных мест. Проверяйте ваш ресурс через ping с множества серверов по всему миру в автоматическом режиме с нашим онлайн сервисом",
      examples: "Примеры",
      location: "Расположение",
      result: "Результат",
      rtt: "rtt мин / макс / средн",
      ip: "IP адрес",
      check: "Проверить веб-сайт",
      checkTitle:
        "Быстрая проверка любого URL-адреса! HTTP или HTTPS с любыми портами.",
      checkDesc:
        "Проверьте доступность и производительность веб-сайта с ответами от множества серверов в разных странах. Это простой способ мониторинга веб-сайта!",
      timeHeader: "Время",
      codeHeader: "Код",
      tcp: "TCP подключение",
      tcpTitle:
        "Этот сервис дает возможность протестировать TCP подключение к любому порту любого IP адреса или веб-сайта из различных мест и стран.",
      tcpDesc:
        "Этот сервис дает возможность протестировать TCP подключение к любому порту любого IP адреса или веб-сайта из различных мест и стран. Проверьте состояния портов на сервере легким способом. Например Вы можете проверить новый порт для SSH у вашего сервера на доступность из различных точек земного шара. Мониторинг веб-сайта или мониторинг любого другого сервиса с помощью этого инструмента может сэкономить значительное время системному администратору.",
      udpConnect: "UDP подключение",
      udpTitle:
        "Проверьте UDP подключение к любому порту любого IP адреса или веб-сайта из различных стран.",
      udpDesc:
        "Проверьте UDP подключение к любому порту любого IP адреса или веб-сайта из различных стран. Например Вы можете проверить доступность сервера DNS или игрового сервера.",
      dnsTitle:
        "DNS проверка очень полезна для мониторинга состояния домена веб-сайта.",
      dnsDesc:
        "Вы можете получить запись для ресурса или IP адреса сразу с нескольких именных серверов разных стран.",
      connection: "Подключение",
      invalidKey:
        "Вы ввели неверный ключ, пожалуйста, проверьте ключ который вы ввели и попробуйте снова",
      mayReturnCaptcha: "Может возвращать catcha",
      topUpBalance: "Пополнить баланс",
      coinText:
        "Выберите криптовалюту в которой вы бы хотели пополнить ваш баланс",
      isChosen: "выбрана сейчас",
      chooseNetwork: "Выберите сеть",
      yourWallet: "Ваш текущий кошелек",
      attention: "Пожалуйста, будьте внимательны совершая транзакцию!",
      attentionCrypt: " Проверьте выбранную криптовалюту",
      attentionNetwork: "и сеть",
      attentionMoney:
        "или ваши деньги будут потеряны. Минимальная сумма транзакции 5$",
      tariffs: "Тарифы",
      concurrentsBig: "Конкурентов",
      secondsSmall: "секунд",
      max: "Макс. ",
      purchasedBig: "Куплено",
      needBig: "Необходимо: ",
      cheaperBig: "Дешевле",
      buyBig: "Купить",
      topUpBig: "Пополнить",
      boughtBig: "Куплено",
      buyWarning:
        "Вы уверены, что хотите купить тариф? Это снимет деньги с вашего баланса!",
      yourBalance: "Ваш баланс:",
      yes: "Да",
      cancel: "Отмена",
      attacksPerBig: "Атаки в день",
      networkBig: "Сеть",
      enabledVip: "VIP Cеть:",
      days: "д.",
      detailsBig: "Детали",
      buyTariff:
        "У вас нет тарифа для доступа к этой странице. Пожалуйста, купите тариф, чтобы продолжить",
      english: "Английский",
      russian: "Русский",
      chinese: "Китайский",
      unavailable: "Недоступно",
      loading: "Выберите монету",
      help: "Помощь",
      help2: "Справка",
      enterQuery: "Введите запрос",
      of: "из",
      showMy: "Показать Мои",
      showAll: "Показать Все",
      mine: "моя",
      dataCopied: "Данные успешно скопированы в форму",
      waitingResponse: "Задача ожидает подтверждение сервера",
      enterPromo: "Введите промокод",
      applied: "Промокод применён",
      empty: "Ваша очередь пуста!",
      clearAll: "Очистить все",
      markAsRead: "Прочитать все",
      onlyUnread: "Только непрочитанные",
      geolocation: "Геолокация",
      geolocationTip: "Источник исходящих запросов на целевой ресурс",
      presets: "Пресеты",
      resetPresets: "Сбросить пресеты",
      preset: "Пресет",
      from: "от",
      wrongHostPreset: "Вы пытаетесь сохранить пресет с неправильным хостом",
      apply: "Применить",
      savedPresets: "Пресет был успешно сохранен!",
      clearedPresets: "Пресеты были успешно сброшены!",
      noPresets: "У вас нет сохраненных пресетов",
      emptyPresets:
        "У вас нет пресетов, пожалуйста, создайте, чтобы продолжить",
      networkLoad: "Нагрузка сети",
      serverConnectionError: "Ошибка соединения с сервером",
      descStarting: "Оценка производительности и удобный интерфейс",
      startingBot: "Бот",
      tgChannel: "Телеграм-канал",
      aboutUs: "О Нас",
      enter: "Войти",
      approach: "НАШ ПОДХОД К ВАШЕЙ ЗАДАЧЕ",
      aboutStarting:
        "С использованием нашей мощной DDoS-сети с передовыми методами и высокой мощностью. Мы предлагаем наиболее эффективные методы, которые могут обойти несколько защит ресурса.",
      titleAbout: "Преимущества DDG STRESSER",
      descAbout:
        "Наш настраиваемый концентратор атак стрессеров позволяет вам обойти пользовательские правила блокировки DDOS-атак, и это поможет достичь вашей цели с большей уверенностью. С нашим концентратором вы можете использовать пользовательские настройки для обхода любой цели, даже если у них есть блокировка DDOS-атак.",
      titlePayment: "Безопасная оплата",
      descPayment:
        "Мы обрабатываем все ваши платежи криптовалютой с нашими собственными узлами, чтобы обеспечить 100% анонимность.",
      securityTitle: "Ваша безопасность важна для нас",
      securityDesc:
        "Стресс-тесты запускаются из нескольких мест и не могут контролироваться. Ваша конфиденциальность в безопасности, никакие журналы не хранятся, и все данные шифруются.",
      launchTitle: "Современные методы запусков",
      launchDesc:
        "Наши методы ddos-атак способны обходить новейшие средства защиты, и наши инженеры постоянно совершенствуют их.",
      supportTitle: "Служба поддержки с вами 24/7",
      supportDesc:
        "Наша поддержка здесь, чтобы помочь вам, если вам что-то нужно, вы можете связаться с нами.",
      maximum: "Максимальное",
      reqDay: "Запусков в день",
      planLimit: "Лимит плана",
      network: "Ceть",
      fastEnter: "Быстрый вход",
      newAccount: "Ввести новую учётную запись",
      enterInAccount: "Вход в аккаунт",
      merchKey: "Мерч-ключ",
      chooseEnterMethod: "Выберите метод входа:",
      chooseAccountEnter: "Выберите учетную запись для входа:",
      register: "Зарегестрироваться",

      modalWinTitle: "DDG Stresser / Official Channels ✅",
      modalWinDisc:
        "В связи с недавними событиями телеграмм каналы были удалены , мы опубликуем ссылки актуальных наших каналов чтобы вы были в курсе всех новостей",
      modalWinDiscMid:
        "Также мы принимаем определенные меры , в связи с которыми данной ситуации не должно больше возникать",
      modalWinDiscEnd:
        "Мы очень ценим то что вы остаетесь с нами , мы сделаем все возможное чтобы данная проблема была последний спасибо ",
      modalWinEnd: "С вами команда DDG ✅",
      link: "Ссылка актуальная на наш телеграмм канал - ",
      site: "Сайт на котором вы сможете увидеть все актуальные наши домены и также ссылки на телеграмм каналы",
      admin: "Администратор -",
    },
  },
  zh: {
    translation: {
      sendAttack: "开始运营",
      showAttacks: "显示攻击",
      attacks: "攻击",
      showHub: "显示中心",
      attackMethod: "攻击方式",
      stopConcurrent: "停止并发",
      stopAllStack: "停止所有堆栈",
      stopAttacks: "停止我的操作",
      cantStop: "无法停止运行",
      restarting: "更新中...",
      mode: "模式",
      refreshed: "成功更新",
      refreshing: "清爽",
      failedRestart: "无法重复操作",
      hostTranslate: "主持人",
      modeTranslate: "方法",
      work: "工作",
      timeTranslate: "时间",
      actions: "时间",
      noAttackData: "没有数据。请开始攻击",
      concurrentsExceed: "活动并发请求超出。你有：",
      canStart: "你可以开始了",
      concurrentsMany: "并行",
      concurrentsTranslate: "并发",
      portTranslate: "港口",
      rateTranslate: "速度",
      postDataTranslate: "发布数据",
      attackDetails: "攻击详情",
      target: "目标",
      started: "开始",
      notStarted: "没有开始",
      timeout: "暂停",
      goWebSite: "转到网站",
      goBot: "转到机器人",
      noTariff: "无关税 :(",
      news: "消息",
      registeredUsers: "注册用户",
      daysOnline: "在线天数",
      testsMade: "进行的测试",
      methods: "方法",
      method: "方法",
      home: "家",
      attackL4L7: "攻击 L4/L7",
      attackTelegram: "攻击 Telegram",
      helpdesc: "服务台",
      contacts: "联系人",
      wrongKey: "您可能已经输入了您的 merch-key",
      couldntStartAttack: "我们无法开始操作，请稍后再试。",
      noRequests: "您今天没有请求",
      attackStarted: "开始运营...",
      noConcurrents: "没有推出任何竞争对手。此方法的服务器已加载，请稍后重试。",
      notAllConcurrents: "攻击已发起，但仅 ",
      notAllConcurrentsPt2: " 竞争者。通过这种方法加载服务器",
      attackSuccess: "操作成功开始",
      somethingWrong: "出了些问题 🙁",
      profile: "轮廓",
      changeAccount: "更改个人资料",
      username: "用户名",
      balance: "平衡",
      tariff: "关税",
      by: "经过: ",
      availableMethods: "可用方法",
      soon: "即将推出...",
      whatIsStresser: "什么是 DDG-Stresser?",
      whatIsStresserContent:
        "DDG-Stresser 是一个电报机器人，它允许您使用我们强大的 DDoS 网络以最先进的方法和最高的功率发送攻击。",
      freeMethods: "有没有免费的方法",
      freeMethodsContent:
        "是的！有基本的 4 级和 7 级方法，您可以免费使用 120 秒，请记住，功率是最小的，与我们的付费方法无法相提并论",
      support: "我如何联系客户支持",
      weHave: "我们有一个 - ",
      telegramAdmin: "电报管理员帐户",
      power: "力量",
      powerContent:
        "不幸的是，没有准确的方法来衡量 DDoS 攻击的真正有效性，这是主要的事情。基于 Gps 和 RQPS 的功率估计完全没有用，应该忽略。我们只能说我们提供了最有效的方法，使其稳定、可靠和长期。攻击是否有效与发送的流量（Gbps 或 Rqps）几乎没有关系，更多的是与发送流量的位置或流量本身的类型有关。一些防御可能会阻止来自一种方法的 100 Gbit/s 攻击，并从另一种方法减少 10 Gbit/s。有些方法可以在图表上发送 100,000 RQPS，但对真实目标或任何 JS 保护都无用。我们尝试提供有效可靠的解决方案，而不是图表上的数字",
      whatIsProtection: "它可以绕过什么保护",
      protectionContent:
        "目前，我们的第 7 层可以绕过 Cloudflare UAM 和大多数其他基于 JavaScript 和 cookie 的保护，我们还提供高度的自定义，允许您更改发布数据、发送自定义 cookie、攻击来源以绕过 geoblock、ip 请求以绕过速率限制、推荐人和其他有用的设置。在不久的将来，我们计划添加对绕过验证码和其他安全功能的支持。我们的 4 级提供了多种方法，其中一些是新方法，可以绕过多种保护",
      privacy:
        "用户隐私和安全 UAM 和大多数其他 JavaScript 和 cookie 保护，我们还提供高度自定义，允许您更改帖子数据、发送自定义 cookie、绕过 geoblock 的攻击源、绕过速率限制的 ip 请求、引荐来源网址和其他有用的设置.在不久的将来，我们计划添加对绕过验证码和其他安全功能的支持。我们的 4 级提供了多种方法，其中一些是新方法，可以绕过多种保护。",
      privacyContent:
        "我们很自豪能够成为最尊重隐私的压力测试服务之一，也是最安全的压力测试服务之一。我们不会保留 IP 地址日志，不会请求电子邮件，也不会在跟踪系统的帮助下侵犯您的隐私。使用河豚对密码进行哈希处理和加盐处理，并且所有内容的编码都考虑到了隐私和安全性。此外，我们从未被黑客攻击过，也没有遭受任何泄漏。",
      contactUs: "联系我们!",
      sup: "支持",
      willHelp: "我们随时为您提供帮助",
      telegramBot: "电报机器人",
      way: "使用我们服务的移动方式",
      bot: "去机器人",
      ourDesc:
        "DDG-Stresser 是一种电报机器人，它允许您使用我们强大的 DDoS 网络以最先进的方法和最高的功率发送攻击。使用我们强大的 DDoS 网络，采用先进的方法和强大的功能。我们提供可以绕过多种资源保护的最有效方法。",
      stoppingAttack: "停止攻击...",
      stoppingAttacks: "停止攻击...",
      attackStopped: "操作已成功停止",
      attacksStopped: "操作已成功停止",
      readMore: "阅读更多",
      readLess: "少读",
      inactive: "无效",
      invalidAttackEntity: "您正在尝试输入不正确的数据，请仔细检查并重试",
      monitoring: "监控",
      ping: "Ping 服务器",
      pingTitle: "使用来自美国、加拿大、欧洲等地的 Ping 检查资源可用性。",
      pingDesc:
        "现在您不需要从不同的地方手动 ping 您的网站或服务器。使用我们的在线服务以自动模式从世界各地的许多服务器 ping 检查您的资源",
      examples: "例子",
      location: "地点",
      result: "结果",
      rtt: "rtt min / max / avg",
      ip: "IP地址",
      check: "检查网站",
      checkTitle: "快速检查任何 URL！具有任何端口的 HTTP 或 HTTPS。",
      checkDesc:
        "通过来自多个国家/地区的多个服务器的响应来检查网站可用性和性能。这是监控网站的简单方法！",
      timeHeader: "时间",
      codeHeader: "代码",
      tcp: "TCP连接",
      tcpTitle:
        "此服务可以测试与来自不同位置和国家/地区的任何 IP 地址或网站的任何端口的 TCP 连接。",
      tcpDesc:
        "检查服务器上的端口状态的简单方法。例如，您可以在您的服务器上检查 SSH 的新端口，以了解来自世界不同地区的可用性。使用此工具监视网站或监视任何其他服务可以为系统管理员节省大量时间。",
      udpConnect: "UDP连接",
      udpTitle: "检查来自不同国家的任何 IP 地址或网站的任何端口的 UDP 连接。",
      udpDesc: "例如，您可以检查 DNS 服务器或游戏服务器的可用性。",
      dnsTitle: "DNS 检查对于监控网站域的状态非常有用。",
      dnsDesc:
        "您可以同时从不同国家/地区的多个名称服务器获取资源或 IP 地址的条目。",
      connection: "联系",
      invalidKey: "您输入的密钥无效，请检查您输入的密钥并重试",
      mayReturnCaptcha: "可以返现",
      topUpBalance: "可以返现",
      coinText: "选择您想要补充余额的加密货币",
      isChosen: "现在选择",
      chooseNetwork: "选择网络",
      yourWallet: "您当前的钱包",
      attention: "交易时请谨慎!",
      attentionCrypt: " 检查您选择的加密货币",
      attentionNetwork: "和网络",
      attentionMoney: "否则你的钱会丢失。最低交易金额 5$",
      tariffs: "费率",
      concurrentsBig: "并发连接",
      secondsSmall: "秒",
      max: "最大. ",
      purchasedBig: "已购买",
      needBig: "需求: ",
      cheaperBig: "更便宜",
      buyBig: "购买",
      topUpBig: "充值",
      boughtBig: "已购买",
      buyWarning: "您确定要购买该套餐吗？它将从您的余额中扣除费用！",
      yourBalance: "您的余额：",
      yes: "是",
      cancel: "取消",
      attacksPerBig: "每日攻击次数",
      networkBig: "网络",
      enabledVip: "VIP 时间:",
      days: " 天",
      detailsBig: "细节",
      buyTariff: "您没有关税访问此页面。请购买关税以继续",
      english: "英语",
      russian: "俄语",
      chinese: "中国人",
      unavailable: "不可用",
      loading: "选择硬币",
      help: "幫助",
      help2: "幫助",
      enterQuery: "输入请求",
      of: "的",
      showMy: "显示我的",
      showAll: "显示所有",
      mine: "我的",
      dataCopied: "数据已成功复制到表单",
      waitingResponse: "任务正在等待服务器确认",
      enterPromo: "输入促销代码",
      applied: "已应用促销代码",
      empty: "你的隊列是空的！",
      clearAll: "清除全部",
      markAsRead: "閱讀全部",
      onlyUnread: "僅未讀",
      geolocation: "地理定位",
      geolocationTip: "目标资源的传出请求来源",
      presets: "预设",
      resetPresets: "重置预设",
      preset: "预设",
      from: "来自",
      wrongHostPreset: "您正在尝试使用错误的主机保存预设",
      apply: "申请",
      savePresets: "预设已成功保存！",
      clearPresets: "预设已成功清除！",
      noPresets: "您没有保存预设",
      emptyPresets: "您没有预设，请创建一个新的",
      networkLoad: "网络负载",
      serverConnectionError: "连接到服务器时出错",
      startingBot: "机器人",
      tgChannel: "电报频道",
      aboutUs: "关于我们",
      enter: "进入",
      descStarting: "性能评估和用户友好的界面",
      approach: "我们对您的任务的处理方法",
      aboutStarting:
        "使用我们强大的 DDoS 网络，技术先进，功率大。 我们提供可以绕过多种资源保护的最有效方法。",
      titleAbout: "DDG STRESSER 的优点",
      descAbout:
        "我们的自定义压力源攻击中心可让您绕过自定义 DDOS 攻击阻止规则，并帮助您更有信心地实现目标。 通过我们的集线器，您可以使用自定义设置绕过任何目标，即使它们具有 DDOS 攻击阻止功能。",
      titlePayment: "安全支付",
      descPayment:
        "我们使用我们自己的节点处理您的所有加密货币付款，以确保 100% 匿名。",
      securityTitle: "您的安全对我们很重要。",
      securityDesc:
        "压力测试是从多个地方运行的并且无法控制。 您的隐私是安全的，不存储任何日志，并且所有数据都经过加密。",
      launchTitle: "现代发射方法",
      launchDesc:
        "我们的DDoS攻击方法能够绕过最新的安全措施，并且我们的工程师正在不断改进它们。",
      supportTitle: "客户支持 24/7 全天候为您服务",
      supportDesc:
        "我们的支持随时为您提供帮助，如果您需要任何帮助，可以联系我们。",
      maximum: "最大限度",
      reqDay: "请求一天",
      planLimit: "计划限额",
      network: "网络",
      fastEnter: "快速输入",
      newAccount: "输入新帐户",
      enterInAccount: "输入帐号",
      merchKey: "商品密钥",
      chooseEnterMethod: "选择输入方式:",
      chooseAccountEnter: "选择账号进入:",
      register: "登记",

      modalWinTitle: "DDG Stresser / Official Channels ✅",
      modalWinDisc:
        "由于telegram频道最近发生的事件已被删除，我们将发布链接到我们当前的频道，以便您了解所有新闻",
      modalWinDiscMid: "我们还采取了某些措施，使这种情况不再发生",
      modalWinDiscEnd:
        "我们真的很感激你和我们在一起，我们会尽最大努力使这个问题最后谢谢",
      modalWinEnd: "DDG团队与您同在！",

      link: "实际联系到我们的报道 - ",
      site: "网站，您将能够看到我们所有的相关领域，并链接到电报频道 - ",
      admin: "管理员 -",
    },
  },
};
