import React, { createContext, useEffect, useState } from "react"
import { ThemeContextType, ThemeProviderType } from "./types"

export const ThemeContext = createContext<ThemeContextType>({
  theme: "light",
  setTheme: undefined,
})

export const ThemeProvider = ({ children }: ThemeProviderType) => {
  const [theme, setTheme] = useState("")

  useEffect(() => {
    const themeValue = localStorage.getItem("theme")
    setTheme(themeValue ? themeValue : "dark")
  }, [])

  useEffect(() => {
    localStorage.setItem("theme", theme)
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
